import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GuideStatusService {

  configService: ConfigService;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  getRoles() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllRoles'), { headers });
  }

  getAgencyPermissions() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAgencyPermissions'), { headers });
  }

  createdStatus(data, roles) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createGuideStatus'),
      {
        'name': data.name,
        'description': data.description,
        'roles': roles
      }, { headers });
  }

  getGuidesStatus() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllGuideStatus'), { headers });
  }

  getGuidesStatusOfRoles() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllGuideStatusOfRoleInSession'), { headers });
  }

  getOneGuideStatus(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('id', id);
    return this.http.get(this.configService.serverIp.concat('/getOneGuideStatus'), { headers, params });
  }

  updateGuideStatus(id, data, roles) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateGuideStatus'),
      {
        'id': id,
        'name': data.name,
        'description': data.description,
        'roles': roles
      }, { headers });
  }

  applyStatus(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addStatusToGuide'),
      data, { headers });
  }

  applyReadjustState(guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/appyGuideStatusMustHaveReadjust'),
      {
        'guide': guide
      }, { headers });
  }

  applyReadjustStateWhitoutArrive(guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/appyGuideStatusMustHaveReadjustWhitoutArrive'),
      {
        'guide': guide
      }, { headers });
  }

  applyRetainedState(guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/appyGuideStatusRetainedInCustoms'),
      {
        'guide': guide
      }, { headers });
  }

  approvePayment(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/executePayment'), data, { headers });
  }

  removeStatus(id, guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/removeStatusFromGuide'),
      {
        'guideStatusGuide_id': id,
        'guide_id': guide
      }, { headers });
  }

  cancelGuide(guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/cancelGuide'), {
      'guide': guide
    }, { headers });
  }

  applyStatusOfDeiveredGuide(guide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/applyStatusOfDeliveredGuide'), {
      'guide_id': guide
    }, { headers });
  }

  searchStatusGuide(numberGuide: string){
    const params = new HttpParams().set('guide', numberGuide);
    return this.http.get(this.configService.serverIp.concat('/searchStatusGuide'), { params });
  }

  searchCarrierStatusGuide(numberGuide: string){
    const params = new HttpParams().set('guide', numberGuide);
    return this.http.get(this.configService.serverIp.concat('/getGuideTrackingByCarrier'), { params });
  }
}
