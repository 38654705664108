import {Routes} from '@angular/router';
import { RegisterComponent } from '../../forms/register/register.component';
import { LoginComponent } from '../../forms/login/login.component';
import { ValidateAccountWithPasswordComponent } from '../../forms/validate-account-with-password/validate-account-with-password.component';
import { ForgotPasswordComponent } from '../../forms/forgot-password/forgot-password.component';
import { RegisterCompanyComponent } from 'src/app/forms/register-company/register-company.component';
import { SearchGuideStatusComponent } from 'src/app/forms/search-guide-status/search-guide-status.component';
import { SharedCalculatorComponent } from 'src/app/shared-calculator/shared-calculator.component';

export const CONTENT_ROUTES: Routes = [

  // Aquí se encuentran las únicas rutas a las que se puede acceder cuando no hay una sesión
  // activa en el sistema. Si se agrega alguna ruta aquí agregarla también en el método showSearch
  // de app.component para no mostrar el seeker en rutas publicas, solo en sesiones activas.

  {
    path: 'content-layout',
    loadChildren: './pages/content-layout-page/content-pages.module#ContentPagesModule'
  },
  {
    path: 'validate-account/:hash',
    loadChildren: './forms/validate-account/validate-account.module#ValidateAccountModule'
  },
  {
    path: 'validate-account-with-password/:hash',
    component: ValidateAccountWithPasswordComponent
  },
  {
    path: 'register-company-info',
    component: RegisterCompanyComponent
},
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  { // seccion de ruta despues del dominio
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'search-guide-status',
    component: SearchGuideStatusComponent
  },
  {
    path: 'shared-calculator',
    component: SharedCalculatorComponent
  }
];
