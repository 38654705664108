import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  configService;
  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService()
  }

  getGuide(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearch'), value
    , { headers });
  }

  getGuideGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchGuide'), value
    , { headers });
  }

  /**
   *
   * @param value
   */
  getUserNameGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchName'), value
    , { headers });
  }

  getUserPhoneGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchPhone'), value
    , { headers });
  }
  getGuideByAnnotations(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchAnnotation'), value
    , { headers });
  }


  getUserNumberLocker(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchNumberLocker'), value
    , { headers });
  }

  getUserWR(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchWR'), value
    , { headers });
  }

  getUserTracking(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchTracking'), value
    , { headers });
  }

  getConsolidateByNumber(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchConsolidated'), value
    , { headers });
  }

  getConsolidateByAWB(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchAWB'), value
    , { headers });
  }
}
