import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class MenuService {

  private menuObservable = new Subject<any>();

  private menus = {
    'invalidCode': {
      'es': 'Código Desconocido',
      'en': 'Unknow Code'
    },
    'HOME': {
      'es': 'Inicio',
      'en': 'Home'
    },
    'PRA': {
      'es': 'Prealertas',
      'en': 'Prealerts'
    },
    'APQ': {
      'es': 'Paquetes',
      'en': 'Packages'
    },
    'ACU': {
      'es': 'Usuarios',
      'en': 'Users'
    },
    'RPA': {
      'es': 'Registrar Prealerta',
      'en': 'Register Prealert'
    },
    'CSP': {
      'es': 'Consultar Prealertas',
      'en': 'See Prealerts'
    },
    'CRO': {
      'es': 'Crear Operario',
      'en': 'Create Operator'
    },
    'RGW': {
      'es': 'Registrar Paquete',
      'en': 'Register Package'
    },
    'PKW': {
      'es': 'Solicitar Envío',
      'en': 'Request Shipping'
    },
    'MSP': {
      // 'es': 'Paquetes Enviados',
      // 'en': 'Sent Packages'
      'es': 'Envíos Aprobados',
      'en': 'Approved Shippings'
    },
    'PDP': {
      'es': 'Pagos Pendientes',
      'en': 'Pending COD payment'
    },
    'PPA': {
      'es': 'Pagos Pendientes',
      'en': 'Pending payment'
    },
    'RPD': {
      'es': 'Solicitar Envío',
      'en': 'Request Shipping'
    },
    'CPK': {
      'es': 'Consolidar Paquete',
      'en': 'Consolidate Package'
    },
    'RAP': {
      'es': 'Registrar Paquete',
      'en': 'Register Package'
    },
    'LOE': {
      'es': 'Listar Operarios',
      'en': 'List Operators'
    },
    'OEP': {
      'es': 'Editar Operarios',
      'en': 'Edit Operators'
    },
    'DPK': {
      'es': 'Despachar Paquete',
      'en': 'Dispatch Package'
    },
    'CEP': {
      // 'es': 'Paquetes Consolidados',
      // 'en': 'Consolidated Packages'
      'es': 'Pagar y Aprobar Envio',
      'en': 'Pay and Approve Shipping'
    },
    'DSC': {
      'es': 'Despachar Paquete',
      'en': 'Dispatch Package'
    },
    'BIC': {
      'es': 'Cajas en Aduana',
      'en': 'Boxes in Customs'
    },
    'ASR': {
      'es': 'Servicios',
      'en': 'Services'
    },
    'ACC': {
      'es': 'Transportadoras',
      'en': 'Carriers'
    },
    'ASH': {
      'es': 'Tiendas',
      'en': 'Shops'
    },
    'CSH': {
      'es': 'Crear Tienda',
      'en': 'Create Shop'
    },
    'CCA': {
      'es': 'Crear Transportadora',
      'en': 'Create Carrier'
    },
    'CSS': {
      'es': 'Crear Servicio',
      'en': 'Create Service'
    },
    'LSH': {
      'es': 'Listar Tiendas',
      'en': 'List Shops'
    },
    'LCC': {
      'es': 'Listar Transportadoras',
      'en': 'List Carriers'
    },
    'CWS': {
      'es': 'Crear WR Con Remitente',
      'en': 'Create WR With Sender'
    },
    'LLU': {
      'es': 'Listar Clientes',
      'en': 'List Users'
    },
    'DCN': {
      'es': 'Listar Contenedores',
      'en': 'List Containers'
    },
    'LWR': {
      'es': 'Listar WR',
      'en': 'List WR'
    },
    'LSR': {
      'es': 'Listar Servicios',
      'en': 'List Services'
    },
    'ECIF': {
      'es': 'Editar Info de Empresa',
      'en': 'Edit Company Info'
    },
    'ACF': {
      'es': 'Configuración',
      'en': 'Settings'
    },
    'CPO': {
      'es': 'Paquetes Consolidados',
      'en': 'Consolidated Packages'
    },
    'RESH': {
      'es': 'Envíos Solicitados',
      'en': 'Requested Shipments'
    },
    'RTCK': {
      'es': 'Rastreador de Paquetes',
      'en': 'Package Tracker'
    },
    'ADT': {
      'es': 'Posiciones arancelarias',
      'en': 'Duty taxes'
    },
    'LDT': {
      'es': 'Listar posiciones arancelarias',
      'en': 'List duty taxes'
    },
    'CDT': {
      'es': 'Crear posición arancelaria',
      'en': 'Create duty tax'
    },
    'PUPRE': {
      'es': 'Solicitudes de recogida',
      'en': 'Pickup requests'
    },
    'WR': {
      'es': 'Bodega',
      'en': 'Warehouse'
    },
    'CALC': {
      'es': 'Calculadora de costos',
      'en': 'Cost Calculator'
    },
    'OTH': {
      'es': 'Otros',
      'en': 'Others'
    },
    'REGWR': {
      'es': 'Registrar WR',
      'en': 'Register WR'
    },
    'STHS': {
      'es': 'Almacenamiento',
      'en': 'Storage'
    },
    'LSTH': {
      'es': 'Listar almacenes',
      'en': 'List storehouses'
    },
    'LTST': {
      'es': 'Listar tipos de almacén',
      'en': 'List storage type'
    },
    'ASHS': {
      'es': 'Crear almacén',
      'en': 'Create storehouse'
    },
    'ASGTP': {
      'es': 'Crear tipo de almacén',
      'en': 'Create storage type'
    },
    'LAPP': {
      'es': 'Pagos COD pendientes',
      'en': 'Pending COD Payments'
    },
    'WRTAWB': {
      'es': 'WR a Guía',
      'en': 'WR to Air Waybill'
    },
    'AAG': {
      'es': 'Agencias',
      'en': 'Agencies'
    },
    'CRAG': {
      'es': 'Crear Agencia',
      'en': 'Create Agency'
    },
    'LIAGE': {
      'es': 'Listar Agencias',
      'en': 'List Agencies'
    },
    'EDMO': {
      'es': 'Editar Módulos',
      'en': 'Edit Modules'
    },
    'CGAG':{
      'en': 'Create Guide',
      'es': 'Crear Guía'
    },
    'LIGUAG':{
      'en': 'List Guides',
      'es': 'Listar Guias'
    },
    'LIPIAG':{
      'en': 'List Pickup Request',
      'es': 'Listar Solicitudes Recogida'
    },
    'COWR': {
      'en': 'Checkout WR',
      'es': 'Facturar WR'
    },
    'EDPAYCRED': {
      'en': 'Payment Credentials',
      'es': 'Credenciales de Pago'
    },
    'DOLLVAL': {
      'en': 'Price of Dollar',
      'es': 'Precio del Dólar'
    },
    'GUID': {
      'en': 'Guides',
      'es': 'Guías'
    },
    'GUIADJ': {
      'en': 'Readjustment of Guide',
      'es': 'Reajuste de Guía'
    },
    'AWB':{
      'en': 'AWB',
      'es':'AWB'
    },
    'LAWB':{
      'en': 'List AWB',
      'es': 'Listar AWB'
    },
    'CREAWB':{
      'en': 'Create AWB',
      'es': 'Crear AWB'
    },
    'ACAWB':{
      'en': 'Air Carriers',
      'es': 'Actores Aereos'
    },
    'CCAWB':{
      'en': 'Create Carrier AWB',
      'es': 'Crear Actor AWB'
    },
    'LCAWB':{
      'en': 'list Carriers AWB',
      'es': 'Listar Actores AWB'
    },
    'ECAWB':{
      'en': 'Edit Carriers AWB',
      'es': 'Editar Actor AWB'
    },
    'LISTCON':{
      'en': 'List Consolidated',
      'es': 'Listar Consolidados'
    },
    'ASG':{
      'en': 'States of Guide',
      'es': 'Estados de Guía'
    },
    'CRGS':{
      'en': 'Create Guide Status',
      'es': 'Crear Estado de Guía'
    },
    'LIGS':{
      'en': 'List Guide Status',
      'es': 'Listar Estados de Guía'
    },
    'ARR':{
      'en': 'Collection Area',
      'es': 'Area de Recogidas'
    },
    'PUGFA':{
      'en': 'Pick up Guide from Agency',
      'es': 'Guía Recogida de la Agencia'
    },
    'LIGU':{
      'en': 'List Guides',
      'es': 'Listar Guías'
    },
    'COAW':{
      'en': 'Register Guide from Agency',
      'es': 'Registrar Guía de Agencia'
    },
    'REGUDC':{
      'en': 'Register Guide Destiny Country',
      'es': 'Registrar Guía País Destino'
    },
    'LGDC':{
      'en':'List Guides',
      'es':'Listar Guias'
    },
    'LGTCDC':{
      'en':'Unpaid Guides',
      'es':'Guías Pendientes de Pago'
    },
    'LGTDDC':{
      'es':'Listar Guías por Despachar',
      'en':'List Guides to be Dispatched'
    },
    'GTDL':{
      'es':'Guía para Entrega Locales',
      'en':'Guides to Delivering Local'
    },
    'ROUT':{
      'es':'Rutas',
      'en':'Routes'
    },
    'CRR':{
      'es':'Crear ruta',
      'en':'Create route'
    },
    'LIR':{
      'es':'Listar Rutas',
      'en':'List Routes'
    },
    'AGMO':{
      'es':'Monitor de Agencia',
      'en':'Agency Monitor'
    },
    'AGST':{
      'es':'Estadisticas Agencia',
      'en':'Agency Stastics'
    },
    'AST':{
      'es':'Estadísticas',
      'en':'Stastics'
    },
    'LICLAG':{
      'es':'Listar Clientes',
      'en':'List Clients'
    },
    'CUA':{
      'es':'Crear Cliente',
      'en':'Create Client'
    },
    'CUCA':{
      'es':'Clientes De Agencia',
      'en':'Agency Clients'
    },
    'AC':{
      'es':'Consultas',
      'en':'Queries'
    },
    'AGCV':{
      'es':'Área De Guías',
      'en':'Area Of Guides'
    },
    'LGWH':{
      'es':'Listar Guías',
      'en':'List Guides '
    },
    'AR':{
      'es':'Área De Reportes',
      'en':'Report Area'
    },
    'RU':{
      'es':'Reporte Usuarios',
      'en':'Users Report'
    },
    'RPEB':{
      'es':'Reporte Paquetes Bodega',
      'en':'Report Packages Warehouse'
    },
    'RPAA':{
      'es':'Reporte Prealertas',
      'en':'Pre-alert Report'
    },
    'RG':{
      'es':'Reporte Guías',
      'en':'Guides Report'
    },
    'ARA':{
      'es':'Reportes',
      'en':'Reports'
    },
    'REA':{
      'es':'Reporte De Entrega',
      'en':'Delivery Report'
    },
    'CES':{
      'es':'Crear Escala',
      'en':'Create Scale'
    },
    'ADE':{
      'es':'Escala',
      'en':'Scale'
    },
    'RE':{
      'es':'Reporte Agencia',
      'en':'Agency Report'
    },
    'AGA':{
      'es':'Guías',
      'en':'Guides'
    },
    'LES':{
      'es':'Listar Escalas',
      'en':'List Scales'
    },
    'ADC':{
      'es':'Excepciones',
      'en':'Exceptions'
    },
    'MR':{
      'es':'Medidas Obligatorias',
      'en':'Required Measures'
    },
    'CWM':{
      'es':'Paises Con Medidas',
      'en':'Countries With Measures'
    },
    'LS':{
      'es': 'Listar Reporte De Entrega',
      'en': 'List Delivery Report'
    },
    'ARJ':{
      'es': 'Área de reajustes',
      'en': 'Readjustment Area'
    },
    'GEG':{
      'es': 'Generar Excel',
      'en': 'Generate Excel'
    },
    'AE':{
      'es': 'Área de empresa',
      'en': 'Company area'
    },
    'GIE':{
      'es': 'Facturar empresa',
      'en': 'Invoice company'
    },
    'LEN':{
      'es':'Listar compañias',
      'en': 'List companies'
    },
    'LIC':
    {
      'es':'Listar invoice',
      'en': 'List invoice'
    },
    'EDG':
    {
      'es':'Editar Guía',
      'en': 'Edit Guide'
    },

    'LRDE':
    {
      'es': 'Reportes entregados',
      'en': 'Report delivered'
    },
    'EDGM':
    {
      'es': 'Editar Guía Maestro',
      'en': 'Edit Guide Master'
    },
    'CGAGM':
    {
      'es':'Crear Guía Maestro',
      'en': 'Create Guide Master'
    },
    'RPL':
    {
      'es': 'Reporte Casillero Virtual',
      'en': 'Virtual Locker Report'
    },
    'RDBA':
    {
      'es': 'Buscar Reporte De Entrega',
      'en': 'Search Delivery Report'
    },
    'GCCL':
    {
      'es': 'Consolidados Cerrados',
      'en': 'Consolidated Closed'
    },
    'LMAWB':
    {
      'es': 'Listar AWB',
      'en': 'List AWB'
    },
    'ASBR':
    {
      'es': 'Aplicar status',
      'en': 'Apply Status'
    },
    'CQ':
    {
      'es': 'Crear Cotizacion',
      'en': 'Create Quote'
    },
    'AQ':
    {
      'es': 'Área de cotización',
      'en': 'Quote area'
    },
    'LQR':
    {
      'es': 'Listar solicitudes cotización',
      'en': 'List quotations request'
    },
    'LUQR':
    {
      'es': 'Listar solicitudes cotización',
      'en': 'List quotations request'
    },
    'GSI':
    {
      'es': 'Búsqueda de información',
      'en': 'Search information'
    },
    'RAT':
    {
      'es': 'Reportes Agencias Master',
      'en': 'Report Agencies Master'
    },
    'GRDM':
    {
      'es': 'Reporte Entrega Maestro',
      'en': 'Delivery Report Master'
    },
    'SM':
    {
      'es': 'Enviar mensajes',
      'en': 'Send Message'
    },
    'UTD':{
      'es': 'Upload TestDelivery',
      'en': 'Cargar prueba de entrega'
    },
    'SG':{
      'en': 'Simple Guide',
      'es': 'Guía simple'
    },
    'DG':{
      'en': 'Daily Report',
      'es': 'Reporte Diario'
    },
    'GDTM':{
      'es':'Guía para Entrega Maestro',
      'en':'Guides to Delivering Master'
    }

  }

  constructor(private languageService: LanguageService) { }


  public getMenuObservable() {
    return this.menuObservable.asObservable();
  }

  public getMenuTitle(language, menuCode) {
    if (!this.menus[menuCode]) {
      return this.menus['invalidCode'][language];
    }
    return this.menus[menuCode][language];
  }


  public loadMenus() {
    let ROUTES = [];
    ROUTES.push(this.getMenuHome());
    const token = localStorage.getItem('currentUser');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    //const menus: any = decodedToken.menu;

    const tokenInfo = localStorage.getItem('tokenInfo');
    const helperInfo = new JwtHelperService();
    const decodedTokenInfo = helperInfo.decodeToken(tokenInfo);
    const menus: any = decodedTokenInfo.menu;
    //const menus: any =JSON.parse(localStorage.getItem("menu"));
    let currentLanguage = this.languageService.getCurrentLanguage();

    for (let i = 0; i < menus.length; i++) {
      let menu = menus[i];
      ROUTES.push({
        path: '',
        title: this.getMenuTitle(currentLanguage, menu['code']),
        icon: menu['icon'],
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: this.getSubMenus(menu['submenu'])
      })
    }
    this.menuObservable.next(ROUTES);
  }

  private getSubMenus(subMenus) {
    let currentLanguage = this.languageService.getCurrentLanguage();
    let submenu = [];

    for (let i = 0; i < subMenus.length; i++) {
      let sub = subMenus[i];
      submenu.push({
        path: sub['path'],
        title: this.getMenuTitle(currentLanguage, sub['code']),
        //icon: sub['icon'],
        icon: '',
        class: 'second-level',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      });
    }
    return submenu;
  }

  private getMenuHome() {
    let currentLanguage = this.languageService.getCurrentLanguage();
    return {
      path: '/home',
      title: this.getMenuTitle(currentLanguage, 'HOME'),
      icon: 'ft-home',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    };
  }

}
