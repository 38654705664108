import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {RegisterComponent} from './forms/register/register.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {LoginComponent} from './forms/login/login.component';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';

import { AuthService } from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import * as $ from 'jquery';
import {AuthGuardChildService} from './shared/auth/auth-guard-child.service';
import { MatCheckboxModule, MatRadioModule, MatTooltipModule, MatExpansionModule, MatTableModule } from '@angular/material';
import {MaterialModule} from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { ValidateAccountWithPasswordComponent } from './forms/validate-account-with-password/validate-account-with-password.component';
import { ForgotPasswordComponent } from './forms/forgot-password/forgot-password.component';
import { CalculatorComponent } from './forms/calculator/calculator.component';
import { MyAddressesComponent } from './forms/my-addresses/my-addresses.component';
import { PickupRequestsComponent } from './forms/pickup-requests/pickup-requests.component';
import { SearchWrComponent } from './forms/search-wr/search-wr.component';
import { AddStorehouseComponent } from './forms/add-storehouse/add-storehouse.component';
import { ListStorehouseComponent } from './forms/list-storehouse/list-storehouse.component';
import { ListStorageTypeComponent } from './forms/list-storage-type/list-storage-type.component';
import { AddStorageTypeComponent } from './forms/add-storage-type/add-storage-type.component';
import { EditStorageTypeComponent } from './forms/edit-storage-type/edit-storage-type.component';
import { EditStorehouseComponent } from './forms/edit-storehouse/edit-storehouse.component';
import { WrToAwbComponent } from './forms/wr-to-awb/wr-to-awb.component';
import { ListAgenciesComponent } from './forms/list-agencies/list-agencies.component';
import { EditAgencyComponent } from './forms/edit-agency/edit-agency.component';
import { SearchWrModule } from './forms/search-wr/search-wr.module';
import { UploadPicturesComponent } from './shared/upload-pictures/upload-pictures.component';
import { UploadPicturesModule } from './shared/upload-pictures/upload-pictures.module';
import { CreateAgencyComponent } from './forms/create-agency/create-agency.component';
import { RegisterCompanyComponent } from './forms/register-company/register-company.component';



// Exportación necesaria para traducciones implementadas con la
// librería @ngx-translate/core
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { ChartsModule } from 'ng2-charts-x';

import { RecaptchaModule } from 'ng-recaptcha';
import { SearchGuideStatusComponent } from './forms/search-guide-status/search-guide-status.component';
import { InterceptorToken } from './shared/auth/interceptor-token';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    SearchGuideStatusComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    PageNotFoundComponent,
    ValidateAccountWithPasswordComponent,
    ForgotPasswordComponent,
    RegisterCompanyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SearchWrModule,
    UploadPicturesModule,
    NgbModule.forRoot(),
    RecaptchaModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ChartsModule
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthGuardChildService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorToken, multi: true },
  
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
