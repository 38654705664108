import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RequestShippingPaymentService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
    //////console.log('esta es la ip: ',this.configService);
  }

  getLocations(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/locationsByUser'), { headers });
  }

  getCountries(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    if (token) {
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.get(this.configService.serverIp.concat('/countries'), { headers });
    } else {
      return this.http.get(this.configService.serverIp.concat('/countries'));
    }
  }
  getStatesByCountry(countryId) {//
    const params = new HttpParams().set('country_id', countryId);
    return this.http.get(this.configService.serverIp.concat('/statesbycountry'), { params });
  }
  getCitiesByState(stateId) {//
    const params = new HttpParams().set('state_id', stateId);
    return this.http.get(this.configService.serverIp.concat('/citiesbystate'), { params });
  }
  registerNewLocation(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createLocation'), data, { headers });
  }
  /*
    postCreatePayment(data) {//<-- no se usa
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.post(this.configService.serverIp.concat('/createPayment'), data, {headers});
    }
  
    postConfirmPaypalPayment(data) {//<-- no se usa
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.post(this.configService.serverIp.concat('/confirmPayment'), data, {headers});
    }*/

  postConfirmPaymentCOD(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/confirmPaymentCOD'), data, { headers });
  }

}
